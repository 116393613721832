<template>
  <div class="trustedby">
    <div class="label" id="section2">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Trusted By</p>
      </div>
    </div>

    <div class="WebFifthBanner">
      <div class="WebFifthBannerContent">
        <div class="webdev_logo travel">
          <a href="https://www.travelstart.com/" alt="TravelStart"
            ><img src="@/assets/logos/logo_TRAVELSTART.svg" alt=""
          /></a>
        </div>
        <div class="webdev_logo a_m">
          <a href="https://www.andersenmaillard.dk/"
            ><img src="@/assets/logos/logo_a&m.svg" alt=""
          /></a>
        </div>
        <div class="webdev_logo gg">
          <a href="https://www.gasolinegrill.com/"
            ><img src="@/assets/logos/logo_gg.svg" alt=""
          /></a>
        </div>
        <div class="webdev_logo prepared">
          <a href="https://preparedblanks.com/"
            ><img src="@/assets/logos/logo_Prepared.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
@media screen and (max-width: 750px) {
  .webdev_logo.prepared > a > img,
  .webdev_logo.a_m > a > img {
    height: 30px;
    width: 110px;
  }
  .webdev_logo.gg > a > img,
  .webdev_logo.travel > a > img {
    height: 30px;
    width: 110px;
  }
  .label {
    padding-top: 20px;
  }
  .WebFifthBanner {
    padding-top: 20px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
  .webdev_logo.prepared > a > img,
  .webdev_logo.a_m > a > img {
    height: 30px;
  }
  .webdev_logo.gg > a > img,
  .webdev_logo.travel > a > img {
    height: 40px;
  }
}
</style>
