<template>
  <div class="contact">
    <div class="firstSectionPadFix">
      <div class="label labelColorWhite textColorBlack">
        <div class="hrdiv hrdiv-grey-color"></div>
        <div class="pdiv">
          <p>&nbsp;Let's Talk</p>
        </div>
      </div>
    </div>

    <div class="ContactContainerContent labelColorWhite">
      <div>
        <h2>Send us an email, or call and say hi.</h2>
      </div>
      <div class="contact-p">
        <p>
          <span><i class="fa fa-envelope"></i></span>
          <a href="mailto:carsten@schellenbauer.co">carsten@schellenbauer.co</a>
        </p>
        <p>
          <span><i class="fa fa-mobile"></i></span>+45 31600646
        </p>
      </div>
      <div class="contactDetails">
        <div class="iconAndDetails">
          <i class="fa fa-envelope"></i>
          <div>
            <a href="mailto:carsten@schellenbauer.co"
              >carsten@schellenbauer.co</a
            >
          </div>
        </div>
        <div class="iconAndDetails">
          <i class="fa fa-mobile"></i>
          <div>
            <a href="tel:+4531600646">+45 31600646</a>
          </div>
        </div>
      </div>

      <div class="contact-footer">
        <div class="consult-logo">
          <img src="@/assets/images/logo_schellenbauer-consult.svg" />
        </div>
        <div class="address">
          Address: Kvaesthusgade 6, Copenhagen 1251, Denmark
        </div>
      </div>
    </div>

    <Testimonials />
    <div class="trustedby">
      <Trustedby />
    </div>
    <WhoWeAre />
    <MainWhatWeDo />
  </div>
</template>

<script>
import Testimonials from "../components/Testimonials.vue";
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Trustedby from "../components/Trustedby.vue";
export default {
  name: "Contact",
  components: { Testimonials, MainWhatWeDo, WhoWeAre, Trustedby },
};
</script>
<style scoped>
.trustedby {
  background: #fff;
}
.who-we-are {
  background: #f8f8f8;
}
@media screen and (min-width: 1340px) {
  .label {
    padding-top: 60px;
  }
  .consult-logo img {
    width: 300px;
  }
}
@media screen and (min-width: 1024px) {
  .label {
    padding-top: 60px;
  }
  .consult-logo img {
    width: 300px;
  }
}
@media screen and (min-width: 750px) and (max-width: 1024px) {
  .label {
    padding-top: 40px;
  }
  div > h2 {
    padding-top: 30px;
  }
}
@media screen and (max-width: 750px) {
  div > h2 {
    padding-top: 15px;
  }
}
</style>
